@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #000;
  /* font-family: "Antonio"; */
  font-family: "Inter", sans-serif;
}

/* blur */
.blury {
  filter: blur(200px);
}

.blurry {
  backdrop-filter: blur(8px);
}
.blur {
  filter: blur(30px);
}

/* navbar */

.display-property {
  display: block;
  visibility: visible;
}

.menubar {
  top: -100vh;
  transition: 0.3s ease-in-out;
}
.menubar-open {
  top: 60px;
  transition: 0.3s ease-in-out;
}

.mobilemenu {
  display: none;
  visibility: hidden;
}

.active {
  background-color: #ffbd59;
  color: #000;
}

@media (max-width: 980px) {
  .display-property {
    display: none;
    visibility: hidden;
  }
  .mobilemenu {
    display: grid;
    visibility: visible;
  }
  .container {
    columns: 2;
    column-gap: 10px;
  }
}

.loader {
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  animation: l8-0 1.5s infinite linear;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  background: #6eeb6b;
  clip-path: polygon(0 0%, 100% 0, 100% 100%);
  animation: inherit;
  animation-name: l8-1;
}
.loader:after {
  --s: -1;
}
@keyframes l8-0 {
  66% {
    transform: skewX(0deg);
  }
  80%,
  100% {
    transform: skewX(-45deg);
  }
}
@keyframes l8-1 {
  0% {
    transform: scale(var(--s, 1)) translate(-0.5px, 0);
  }
  33% {
    transform: scale(var(--s, 1)) translate(calc(1px - 50%), calc(1px - 50%));
  }
  66% {
    transform: scale(var(--s, 1)) translate(calc(1px - 50%), 0%);
  }
  100% {
    transform: scale(var(--s, 1)) translate(calc(0.5px - 50%), 0%);
  }
}

/* button */

.radial-gradient {
  background: radial-gradient(
      circle at 50% 0%,
      rgba(250, 250, 250, 0.05) 0%,
      transparent 60%
    ),
    rgba(15, 15, 15, 1);
}

.linear-mask {
  mask-image: linear-gradient(
    -75deg,
    white calc(var(--x) + 20%),
    transparent calc(var(--x) + 30%),
    white calc(var(--x) + 100%)
  );
  -webkit-mask-image: linear-gradient(
    -75deg,
    white calc(var(--x) + 20%),
    transparent calc(var(--x) + 30%),
    white calc(var(--x) + 100%)
  );
}

.linear-overlay {
  background-image: linear-gradient(
    -75deg,
    rgba(255, 255, 255, 0.1) calc(var(--x) + 20%),
    rgba(255, 255, 255, 0.5) calc(var(--x) + 25%),
    rgba(255, 255, 255, 0.1) calc(var(--x) + 100%)
  );
  mask: linear-gradient(black, black) content-box, linear-gradient(black, black);
  -webkit-mask: linear-gradient(black, black) content-box,
    linear-gradient (black, black);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
}

.range {
  appearance: none;
  width: 100%;
  background: white;
  height: 2px;
}

.range::-webkit-slider-thumb {
  appearance: none;
  background: #f59e0b;
  border: 2px solid white;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  cursor: pointer;
}

.range::-moz-range-thumb {
  appearance: none;
  background: #f59e0b;
  border: 2px solid white;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  cursor: pointer;
}

/* hero image */

.heroBgImage {
  background-image: url("./assets/eyesCover.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* grid bg */

.grid-bg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05),
      transparent 0%,
      transparent 100%,
      rgba(0, 0, 0, 0.05)
    ),
    linear-gradient(
      0deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.08) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    );
  background-size: 120px 2px;
}


/* strock */
.strock {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  text-transform: uppercase;
}